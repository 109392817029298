<template>
  <v-container fluid>
    <v-row>
      <v-col cols="4" class="pb-0 pl-4">
        <perfect-scrollbar
          :options="{ suppressScrollX: true }"
          class="scroll"
          style="max-height: 89vh position: relative"
        >
          <table>
            <tr>
              <th colspan="2" class="font-size-18 py-2">Basic Information</th>
            </tr>
            <tr class="">
              <td class="font-size-18 font-weight-500 py-2">Equipment Name</td>
              <td class="font-weight-500 font-size-18 py-2">
                <template v-if="detail && detail.name">
                  <div class="text-capitalize">{{ detail.name }}</div>
                </template>
                <template v-else> no Equipment Name </template>
              </td>
            </tr>
            <tr class="line-height5">
              <td class="font-size-18 font-weight-500 py-2">Serial No.</td>
              <td class="font-weight-500 font-size-18 py-2">
                <template v-if="detail && detail.serial_no">
                  <v-chip outlined text-color="" label small color="green">
                    {{ detail.serial_no }}
                  </v-chip>
                </template>
                <template v-else> no Serial no. </template>
              </td>
            </tr>
            <tr class="line-height5">
              <td class="font-size-18 font-weight-500 py-2">SR/AR/BR Number</td>
              <td class="font-weight-500 font-size-18 py-2">
                <template v-if="detail && detail.batch_number">
                  <span class="text-capitalize">{{ detail.batch_number }}</span>
                </template>
                <template v-else>
                  <span class="text-muted">no SR/AR/BR Number </span></template
                >
              </td>
            </tr>
            <tr class="line-height5">
              <td class="font-size-18 font-weight-500 py-2">Selling Price</td>
              <td class="font-weight-500 font-size-18 py-2">
                <template v-if="detail && detail.selling_price">
                  <!-- <span class="text-capitalize">{{ formatMoney(detail.selling_price) }}</span> -->
                  <v-chip outlined text-color="" label small color="blue">
                    {{ formatMoney(detail.selling_price) }}
                  </v-chip>
                </template>
                <template v-else> no Selling Price </template>
              </td>
            </tr>
            <tr
              class=""
              v-if="detail && detail.customer && detail.customer.company_name"
            >
              <td
                class="font-size-18 font-weight-500 py-2"
                style="min-width: 120px !important; width: 50% !important"
              >
                Company Name
              </td>
              <td class="font-weight-500 font-size-18 py-2">
                <template
                  v-if="
                    detail && detail.customer && detail.customer.company_name
                  "
                >
                  <span
                    class="text-capitalize"
                    @click="goToCustomer(detail.customer.id)"
                  >
                    <a href="javascript:void(0);" style="color: #5867dd">{{
                      detail.customer.company_name
                    }}</a>
                  </span>
                </template>
                <template v-else>
                  <em class="text-muted"> no company name</em>
                </template>
              </td>
            </tr>
            <tr
              v-if="
                detail &&
                detail.property &&
                detail.property.property_address &&
                detail.main_type != 'main_company'
              "
            >
              <td class="font-size-18 font-weight-500 py-2 d-flex">
                Site Location
              </td>
              <td class="font-weight-500 font-size-18 py-2">
                <template
                  v-if="
                    detail &&
                    detail.property &&
                    detail.property.property_address
                  "
                >
                  {{ getFormattedProperty(detail.property) }}
                </template>
                <template v-else>
                  <em class="text-muted"> no site location </em>
                </template>
              </td>
            </tr>
            <tr
              v-if="
                detail && detail.main_type == 'main_company' && detail.entity
              "
            >
              <td class="font-size-18 font-weight-500 py-2 d-flex">
                Site Location
              </td>
              <td class="font-weight-500 font-size-18 py-2">
                <template v-if="detail && detail.entity">
                  {{ getFormattedCompany() }}
                </template>
                <template v-else>
                  <em class="text-muted"> no site location </em>
                </template>
              </td>
            </tr>
            <tr v-if="detail.tags && detail.tags.length > 0">
              <th colspan="2" class="font-size-18 py-2">Tags</th>
            </tr>
            <tr
              class="line-height5"
              v-if="detail.tags && detail.tags.length > 0"
            >
              <td class="font-weight-500 font-size-18 py-2" colspan="2">
                <template v-if="detail.tags && detail.tags.length > 0">
                  <v-chip
                    v-for="(row, index) in detail.tags"
                    :key="index"
                    style="height: 26px"
                    class="mr-2"
                    text-color="white"
                    :color="row.color"
                    >{{ row.text }}</v-chip
                  >
                </template>
                <em v-else class="text-muted"> no tags</em>
              </td>
            </tr>

            <tr>
              <th colspan="2" class="font-size-18 py-2"></th>
            </tr>

            <tr>
              <th colspan="2" class="font-size-18 py-2">Delivery Info</th>
            </tr>
            <tr class="">
              <td class="font-size-18 font-weight-500 py-2">Do No.</td>
              <td class="font-weight-500 font-size-18 py-2">
                <template v-if="detail && detail.do_no">
                  {{ detail.do_no }}
                </template>
                <template v-else>
                  <em class="text-muted"> no Do No. </em>
                </template>
              </td>
            </tr>
            <tr class="">
              <td class="font-size-18 font-weight-500 py-2">
                Commission Certificate No.
              </td>
              <td class="font-weight-500 font-size-18 py-2">
                <template v-if="detail && detail.common_cert_no">
                  {{ detail.common_cert_no }}
                </template>
                <template v-else>
                  <em class="text-muted"> no Commission Certificate No.</em>
                </template>
              </td>
            </tr>
            <tr class="">
              <td class="font-size-18 font-weight-500 py-2">Sales Employee</td>
              <td class="font-weight-500 font-size-18 py-2">
                <template v-if="detail && detail.sales_employee">
                  {{ detail.sales_employee }}
                </template>
                <template v-else>
                  <em class="text-muted"> no Sales Employee</em>
                </template>
              </td>
            </tr>
            <tr class="">
              <td class="font-size-18 font-weight-500 py-2">Delivery Date</td>
              <td class="font-weight-500 font-size-18 py-2">
                <template v-if="detail && detail.delivery_date">
                  {{ formatDate(detail.delivery_date) }}
                </template>
                <template v-else>
                  <em class="text-muted"> no Delivery Date</em>
                </template>
              </td>
            </tr>
            <tr class="">
              <td class="font-size-18 font-weight-500 py-2">Delivery Remark</td>
              <td class="font-weight-500 font-size-18 py-2">
                <template v-if="detail && detail.delivery_remark">
                  {{ detail.delivery_remark }}
                </template>
                <template v-else>
                  <em class="text-muted"> no Delivery Remark</em>
                </template>
              </td>
            </tr>
            <tr>
              <th colspan="2" class="font-size-18 py-2"></th>
            </tr>

            <tr>
              <th colspan="2" class="font-size-18 py-2">Dates</th>
            </tr>
            <tr class="">
              <td class="font-size-18 font-weight-500 py-2">
                Installation Date
              </td>
              <td class="font-weight-500 font-size-18 py-2">
                <template v-if="detail && detail.installation_date">
                  {{ formatDate(detail.installation_date) }}
                </template>
                <template v-else>
                  <em class="text-muted"> no Installation Date </em>
                </template>
              </td>
            </tr>
            <tr class="">
              <td class="font-size-18 font-weight-500 py-2">Commission Date</td>
              <td class="font-weight-500 font-size-18 py-2">
                <template v-if="detail && detail.activation_date">
                  {{ formatDate(detail.activation_date) }}
                </template>
                <template v-else>
                  <em class="text-muted"> no Commission Date </em>
                </template>
              </td>
            </tr>
            <tr class="">
              <td class="font-size-18 font-weight-500 py-2">End Life</td>
              <td class="font-weight-500 font-size-18 py-2">
                <template v-if="detail && detail.end_life">
                  {{ formatDate(detail.end_life) }}
                </template>
                <template v-else>
                  <em class="text-muted"> no End Life </em>
                </template>
              </td>
            </tr>
          </table>
        </perfect-scrollbar>
      </v-col>
      <v-col
        cols="4"
        class="pb-0 gray-background pl-4"
        style="border-left: 1px solid lightgray !important"
      >
        <!-- v-if="
          ( detail.faulty || detail.retired ) ||
          (detail.has_warranty == 1 &&
            detail.warranty &&
            detail.warranty.start_date) || (detail.has_preventive_maintanance == 1 &&
              detail.preventive_maintanance)" -->
        <perfect-scrollbar
          ref="sidebarScrollbar"
          :options="{ suppressScrollX: true }"
          class="scroll"
          style="max-height: 89vh position: relative"
        >
          <table class="width-100">
            <tr>
              <th colspan="2" class="font-size-18 py-2">Other Information</th>
            </tr>

            <tr
              v-for="(field, index) in tOneFields"
              :key="'tOneFields' + index"
            >
              <td class="font-size-18 font-weight-500 py-2 d-flex">
                {{ field.title }}
              </td>
              <td class="font-weight-500 font-size-18 py-2">
                <template v-if="dataLoading">
                  <v-skeleton-loader
                    class="custom-skeleton width-80"
                    type="text"
                  >
                  </v-skeleton-loader>
                </template>
                <template v-else>
                  <template v-if="field.child && detail[field.key]">
                    {{ detail[field.key][field.child] }}
                  </template>
                  <template v-else-if="detail[field.key]">
                    <template
                      v-if="
                        field.key == 'end_life' ||
                        field.key == 'activation_date' ||
                        field.key == 'installation_date'
                      "
                    >
                      {{ formatDate(detail[field.key]) }}
                    </template>
                    <template v-else-if="field.key == 'serial_no'">
                      <v-chip color="cyan" label small text-color="white">
                        {{ detail[field.key] }}
                      </v-chip>
                    </template>
                    <template v-else-if="field.key == 'location'">
                      {{ detail[field.key].text }}
                    </template>
                    <template v-else-if="field.key == 'property'">
                      {{ detail[field.key].property_address }}
                    </template>
                    <template v-else-if="field.key == 'customer'">
                      {{ detail[field.key].company_name }}
                    </template>
                    <template v-else>
                      {{ detail[field.key] }}
                    </template>
                  </template>
                  <!--     <template v-else-if="field.key == 'end_life'">
                    {{ formatDate(detail[field.key]) }}
                  </template> -->
                  <!--  <template v-else-if="detail[field.key]">
                    {{ detail[field.key] }}
                  </template> -->
                  <template v-else>
                    <em class="text--secondary">No {{ field.title }}</em>
                  </template>
                </template>
              </td>
            </tr>
            <tr>
              <td class="font-size-18 font-weight-500 py-2 d-flex">
                Location
              </td>
              <td class="font-weight-500 font-size-18 py-2" v-if="detail?.location?.text">
                {{ detail?.location?.text }}
              </td>
              <td v-else>
                <em class="text--secondary">No location</em>
              </td>
            </tr>
          </table>

          <table
            class="width-100"
            v-if="
              detail &&
              detail.has_warranty == 1 &&
              detail.warranty &&
              detail.warranty.start_date
            "
          >
            <tr>
              <th colspan="2" class="font-size-18 py-2">
                Warranty Information
              </th>
            </tr>
            <tr class="line-height5">
              <td class="font-size-18 font-weight-500 py-2" width="200">
                Warranty #
              </td>
              <td class="font-weight-600 font-size-18 py-2">
                <template
                  v-if="detail && detail.warranty && detail.warranty.unique_id"
                >
                  <Barcode
                    route="warranty.detail"
                    :barcode="detail.warranty.unique_id"
                    :id="detail.warranty.id"
                  >
                  </Barcode>
                </template>
                <template v-else> no serial no </template>
              </td>
            </tr>
            <tr class="line-height5">
              <td class="font-size-18 font-weight-500 py-2" width="200">
                Start Date
              </td>
              <td class="font-weight-600 font-size-18 py-2">
                <template
                  v-if="detail && detail.warranty && detail.warranty.start_date"
                >
                  <v-chip outlined text-color="" label small color="green">
                    {{ formatDate(detail.warranty.start_date) }}
                  </v-chip>
                </template>
                <template v-else> no start date </template>
              </td>
            </tr>
            <tr class="line-height5">
              <td class="font-size-18 font-weight-500 py-2" width="250">
                End Date
              </td>
              <td class="font-weight-600 font-size-18 py-2">
                <template
                  v-if="detail && detail.warranty && detail.warranty.end_date"
                >
                  <v-chip outlined text-color="" label small color="red">
                    {{ formatDate(detail.warranty.end_date) }}
                  </v-chip>
                </template>
                <template v-else> no End date </template>
              </td>
            </tr>
            <tr class="line-height5">
              <td class="font-size-18 font-weight-500 py-2" width="250">
                Remaining
              </td>
              <td class="font-weight-600 font-size-18 py-2">
                <template
                  v-if="detail && detail.warranty && detail.warranty.end_date"
                >
                  {{ remaningDate(detail.warranty) }}

                  <div style="color: red; font-weight: 700; margin-top: 1px">
                    <br />
                    ({{ totalDays(detail.warranty) }} Days)
                  </div>
                </template>
              </td>
            </tr>
            <tr class="line-height5">
              <td class="font-size-18 font-weight-500 py-2" width="250">
                Cost
              </td>
              <td class="font-weight-600 font-size-18 py-2">
                <template
                  v-if="detail && detail.warranty && detail.warranty.cost"
                >
                  <v-chip outlined text-color="" label small color="blue">
                    {{ formatMoney(detail.warranty.cost) }}
                  </v-chip>
                </template>
                <template v-else>
                  {{ detail.pm_one_serial_number }}
                </template>
              </td>
            </tr>
            <tr class="line-height5">
              <td class="font-size-18 font-weight-500 py-2" width="250">
                Status
              </td>
              <td class="font-weight-600 font-size-18 py-2">
                <template
                  v-if="detail && detail.warranty && detail.warranty.status"
                >
                  <v-chip
                    small
                    label
                    text-color="white"
                    :color="getStatusColor(detail.warranty.status)"
                    v-if="detail.warranty.status"
                  >
                    {{ getStatusText(detail.warranty.status) }}
                  </v-chip>
                </template>
              </td>
            </tr>
          </table>

          <!-- Preventive Maintenance -->
          <table
            class="width-100 mt-4"
            v-if="
              detail &&
              /*      detail.has_preventive_maintanance == 1 && */
              detail.preventive_maintanance &&
              detail.preventive_maintanance.start_date
            "
          >
            <tr>
              <th colspan="2" class="font-size-18 py-2">
                Preventive Maintenance Information
              </th>
            </tr>
            <tr class="line-height5">
              <td class="font-size-18 font-weight-500 py-2" width="200">
                Preventive Maintenance #
              </td>
              <td class="font-weight-600 font-size-18 py-2">
                <template
                  v-if="
                    detail &&
                    detail.preventive_maintanance &&
                    detail.preventive_maintanance.unique_id
                  "
                >
                  <Barcode
                    route="preventive-maintanance.detail"
                    :barcode="detail.preventive_maintanance.unique_id"
                    :id="detail.preventive_maintanance.id"
                  >
                  </Barcode>
                </template>
                <template v-else> no serial no </template>
              </td>
            </tr>
            <tr class="line-height5">
              <td class="font-size-18 font-weight-500 py-2" width="200">
                Start Date
              </td>
              <td class="font-weight-600 font-size-18 py-2">
                <template
                  v-if="
                    detail &&
                    detail.preventive_maintanance &&
                    detail.preventive_maintanance.start_date
                  "
                >
                  <v-chip outlined text-color="" label small color="green">
                    {{ formatDate(detail.preventive_maintanance.start_date) }}
                  </v-chip>
                </template>
                <template v-else> no start date </template>
              </td>
            </tr>
            <tr class="line-height5">
              <td class="font-size-18 font-weight-500 py-2" width="250">
                End Date
              </td>
              <td class="font-weight-600 font-size-18 py-2">
                <template
                  v-if="
                    detail &&
                    detail.preventive_maintanance &&
                    detail.preventive_maintanance.end_date
                  "
                >
                  <v-chip outlined text-color="" label small color="red">
                    {{ formatDate(detail.preventive_maintanance.end_date) }}
                  </v-chip>
                </template>
                <template v-else> no End date </template>
              </td>
            </tr>
            <tr class="line-height5">
              <td class="font-size-18 font-weight-500 py-2" width="250">
                Remaining
              </td>
              <td class="font-weight-600 font-size-18 py-2">
                <template
                  v-if="
                    detail &&
                    detail.preventive_maintanance &&
                    detail.preventive_maintanance.end_date
                  "
                >
                  {{ remaningDate(detail.preventive_maintanance) }}
                  <div style="color: red; font-weight: 700; margin-top: 1px">
                    <br />
                    ({{ totalDays(detail.preventive_maintanance) }} Days)
                  </div>
                </template>
              </td>
            </tr>
            <tr class="line-height5">
              <td class="font-size-18 font-weight-500 py-2" width="250">
                Cost
              </td>
              <td class="font-weight-600 font-size-18 py-2">
                <template
                  v-if="
                    detail &&
                    detail.preventive_maintanance &&
                    detail.preventive_maintanance.cost
                  "
                >
                  <v-chip outlined text-color="" label small color="blue">
                    {{ formatMoney(detail.preventive_maintanance.cost) }}
                  </v-chip>
                </template>
                <template v-else>
                  {{ detail.pm_one_serial_number }}
                </template>
              </td>
            </tr>
            <tr class="line-height5">
              <td class="font-size-18 font-weight-500 py-2" width="250">
                Status
              </td>
              <td class="font-weight-600 font-size-18 py-2">
                <template
                  v-if="
                    detail &&
                    detail.preventive_maintanance &&
                    detail.preventive_maintanance.status
                  "
                >
                  <v-chip
                    small
                    label
                    text-color="white"
                    :color="
                      getStatusColor(detail.preventive_maintanance.status)
                    "
                    v-if="detail.preventive_maintanance.status"
                  >
                    {{ getStatusText(detail.preventive_maintanance.status) }}
                  </v-chip>
                </template>
              </td>
            </tr>
          </table>

          <table class="width-100" v-if="detail.is_purchase_info == 1 && 0">
            <tr>
              <th colspan="2" class="font-size-18 py-2"></th>
            </tr>
            <tr>
              <th colspan="2" class="font-size-18 py-2">
                Purchased Information
              </th>
            </tr>
            <tr class="line-height5">
              <td class="font-size-18 font-weight-500 py-2" width="250">
                Supplier
              </td>
              <td class="font-weight-600 font-size-18 py-2">
                <template
                  v-if="
                    detail && detail.supplier && detail.supplier.display_name
                  "
                >
                  {{ detail.supplier.display_name }}
                </template>
                <em class="text-muted" v-else> no supplier </em>
              </td>
            </tr>
            <tr class="line-height5">
              <td class="font-size-18 font-weight-500 py-2" width="250">
                Purchased On
              </td>
              <td class="font-weight-600 font-size-18 py-2">
                <template v-if="detail && detail.purchased_on">
                  {{ formatDate(detail.purchased_on) }}
                </template>
                <em class="text-muted" v-else> no purchased date </em>
              </td>
            </tr>
            <tr class="line-height5">
              <td class="font-size-18 font-weight-500 py-2" width="250">
                Cost Price
              </td>
              <td class="font-weight-600 font-size-18 py-2">
                <template v-if="detail && detail.cost_price">
                  {{ formatMoney(detail.cost_price) }}
                </template>
                <em v-else class="text-muted"> no cost price</em>
              </td>
            </tr>
          </table>

          <table class="width-100" v-if="detail.faulty || detail.retired">
            <tr>
              <th colspan="2" class="font-size-18 py-2"></th>
            </tr>
            <tr>
              <th colspan="2" class="font-size-18 py-2" v-if="detail.faulty">
                Faulty Remark
              </th>
              <th colspan="2" class="font-size-18 py-2" v-if="detail.retired">
                Retired Remark
              </th>
            </tr>
            <tr class="line-height5">
              <!-- <td class="font-size-18 font-weight-500 py-2" width="250">
                Remark
              </td> -->
              <td
                class="font-weight-600 font-size-18 py-2"
                style="line-height: normal"
              >
                <template v-if="detail && detail.faulty">
                  <span>{{ detail.faulty_remark }}</span>
                </template>
                <template v-else-if="detail && detail.retired">
                  <span>{{ detail.retired_remark }}</span>
                </template>
                <em class="text-muted" v-else> no remark </em>
              </td>
            </tr>
          </table>
        </perfect-scrollbar>
      </v-col>
      <v-col
        cols="4"
        class="pb-0 gray-background pl-4"
        style="border-left: 1px solid lightgray !important"
      >
        <perfect-scrollbar
          ref="sidebarScrollbar"
          :options="{ suppressScrollX: true }"
          class="scroll"
          style="max-height: 89vh position: relative"
        >
          <table class="width-100">
            <tr>
              <th class="font-size-18 py-2">Image</th>
            </tr>
            <tr>
              <td class="font-size-18 font-weight-500 py-2" width="200">
                <v-img
                  :lazy-src="$defaultImage"
                  :src="getProfileImage()"
                  aspect-ratio="1"
                  max-height="200"
                  max-width="200"
                  class="margin-auto custom-grey-border custom-border-radius-50"
                  transition="fade-transition"
                >
                </v-img>
                <Status v-if="false" :status="equipmentActivated"></Status>
              </td>
            </tr>
          </table>
        </perfect-scrollbar>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import moment from "moment-timezone";
import Status from "@/view/pages/partials/Status.vue";
import Barcode from "@/view/pages/partials/Barcode.vue";
import { toSafeInteger } from "lodash";
/* import { QUERY } from "@/core/services/store/request.module"; */
import CommonMixin from "@/core/plugins/common-mixin";
moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);

export default {
  mixins: [CommonMixin],
  data() {
    return {
      equipment: 0,
      dataLoading: true,
      chartOptions: {
        chart: {
          id: "equipment-summary",
        },
        xaxis: {
          categories: [],
        },
        dataLabels: {
          enabled: true,
          style: {
            fontFamily: "'Poppins', Helvetica, Arial, sans-serif",
          },
        },
      },
      series: [],
      tOneFields: [
        {
          title: "Brand",
          key: "brand",
          child: false,
        },
        {
          title: "Model",
          key: "model",
          child: false,
        },
        {
          title: "Category",
          key: "category",
          child: false,
        },
        /* {
          title: "Contact For Item",
          key: "company_number",
          child: false,
        },*/
        {
          title: "Description",
          key: "description",
          child: false,
        }
      ],
    };
  },
  props: {
    detail: {
      type: Object,
      required: true,
      default: () => {
        return new Object();
      },
    },
  },
  watch: {
    detail: {
      deep: true,
      immediate: true,
      handler(param) {
        this.equipment = this.lodash.toSafeInteger(param.id);
        this.dataLoading = false;
      },
    },
  },
  methods: {
    goToCustomer(cid = 0) {
      let _this = this;
      _this.$router.push(
        _this.getDefaultRoute("customer.detail", {
          params: { id: cid },
        })
      );
    },
    remaningDate(date) {
      var a = moment(date.end_date);
      //var b = moment(date.start_date);
      //console.log(b);
      var b = moment().format("YYYY-MM-DD");
      b = moment(b);
      var years = a.diff(b, "year");
      b.add(years, "years");

      var months = a.diff(b, "months");
      b.add(months, "months");

      var days = a.diff(b, "days");
      if (days <= 0) {
        days = "";
      } else {
        days = days + " Days";
      }
      if (months <= 0) {
        months = "";
      } else {
        months = months + " Months ";
      }
      if (years <= 0) {
        years = "";
      } else {
        years = years + " Years ";
      }
      return years + months + days;
    },
    totalDays(date) {
      // var a =  moment(date.end_date);
      // var b =  moment(date.start_date);
      // var final =   a.diff(b, 'days')   // =1
      // return final;
      let today = moment().format("YYYY-MM-DD");

      var a = moment(date.end_date);
      var b = moment(today); //date.start_date
      var final = a.diff(b, "days"); // =1
      if (final <= 0) {
        final = 0;
      }
      return final;
    },
    getProfileImage() {
      if (!this.detail.image) {
        return null;
      }
      return this.detail.image;
    },
    getStatusText(data) {
      if (toSafeInteger(data) == 1) {
        return "Draft";
      } else if (toSafeInteger(data) == 2) {
        return "Active";
      } else if (toSafeInteger(data) == 3) {
        return "Extended";
      } else if (toSafeInteger(data) == 4) {
        return "Terminated";
      } else if (toSafeInteger(data) == 5) {
        return "Expired";
      } else if (toSafeInteger(data) == 6) {
        return "Renewed";
      } else if (toSafeInteger(data) == 7) {
        return "Approved";
      } else if (toSafeInteger(data) == 8) {
        return "Rejected";
      }
    },
    getFormattedProperty(row) {
      let tempArr = [];
      if (row.unit_no) {
        tempArr.push(row.unit_no);
      }
      if (row.street_1) {
        tempArr.push(row.street_1);
      }
      if (row.street_2) {
        tempArr.push(row.street_2);
      }

      if (row.country) {
        tempArr.push(row.country);
      }
      return tempArr.join(", ");
    },
    getFormattedCompany() {
      let tempArr = [];
      if (this.detail.entity.unit_no) {
        tempArr.push(this.detail.entity.unit_no);
      }
      if (this.detail.entity.street_1) {
        tempArr.push(this.detail.entity.street_1);
      }
      if (this.detail.entity.street_2) {
        tempArr.push(this.detail.entity.street_2);
      }

      if (this.detail.entity.zip_code) {
        tempArr.push(this.detail.entity.zip_code);
      }
      if (this.detail.entity.landmark) {
        tempArr.push(this.detail.entity.landmark);
      } else {
        tempArr.push("Singapore");
      }
      /*   if (this.lodash.isEmpty(row.city) === false) {
        tempArr.push(row.city.name);
      }
      if (this.lodash.isEmpty(row.state) === false) {
        tempArr.push(row.state.name);
      }
      if (this.lodash.isEmpty(row.country) === false) {
        tempArr.push(row.country);
      } */
      return tempArr.join(", ");
    },
    getStatusColor(data) {
      if (toSafeInteger(data) == 1) {
        return "blue";
      } else if (toSafeInteger(data) == 2) {
        return "green";
      } else if (toSafeInteger(data) == 3) {
        return "orange";
      } else if (toSafeInteger(data) == 4) {
        return "red";
      } else if (toSafeInteger(data) == 5) {
        return "teal";
      } else if (toSafeInteger(data) == 6) {
        return "blue";
      } else if (toSafeInteger(data) == 7) {
        return "green";
      } else if (toSafeInteger(data) == 8) {
        return "red";
      }
    },
  },
  mounted() {
    /*  this.getSummary(); */
  },
  computed: {
    equipmentActivated() {
      const _this = this;
      if (_this.lodash.isEmpty(_this.detail) === false) {
        return _this.detail.activated == 1 ? true : false;
      }
      return false;
    },
  },
  components: {
    Status,
    Barcode,
  },
};
</script>
